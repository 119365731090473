import { createTheme } from "@material-ui/core";

import colors from './colors';

const theme = createTheme({
    palette: {
        type: "dark",
        primary: colors.blue,
        secondary: colors.grey,
        tertiary: colors.purple,
    },
    typography: {
        fontFamily: [
            'Almarai',
            'Alef',
            'sans-serif'
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
          fontFamily: 'Alef',
          fontWeight: 300,
          fontSize: '3.75rem',
          lineHeight: 1.167
        },
        h2: {
          fontFamily: 'Alef',
          fontWeight: 300,
          fontSize: '2.5rem',
          lineHeight: 1.2
        },
        h3: {
          fontFamily: 'Alef',
          fontWeight: 400,
          fontSize: '2.125rem',
          lineHeight: 1.167
        },
        h4: {
          fontFamily: 'Alef',
          fontWeight: 400,
          fontSize: '1.5rem',
          lineHeight: 1.235
        },
        h5: {
          fontFamily: 'Alef',
          fontWeight: 400,
          fontSize: '1.25rem',
          lineHeight: 1.334
        },
        h6: {
          fontFamily: 'Almarai,Alef,sans-serif',
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: 1.6,
          textDecoration: 'underline'
        },
        subtitle1: {
          fontFamily: 'Alef',
          fontWeight: 400,
          fontSize: '1.25rem',
          lineHeight: 1.75,
          letterSpacing: '0.1rem',
        },
        subtitle2: {
          fontFamily: 'Almarai,Alef,sans-serif',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.57
        },
        body1: {
          fontFamily: 'Almarai',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.5
        },
        body2: {
          fontFamily: 'Almarai',
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: 1.43
        },
        button: {
          fontFamily: 'Almarai,Alef,sans-serif',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.75,
          textTransform: 'uppercase'
        },
        caption: {
          fontFamily: 'Almarai,Alef,sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 1.66
        },
        overline: {
          fontFamily: 'Almarai,Alef,sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 2.66,
          textTransform: 'uppercase'
        }
    },
  });

export default theme;
