const colors = {
    blue: {
        main: '#4FC3F7',
        light: 'rgb(114, 207, 248)',
        dark: 'rgb(55, 136, 172)',
        contrastText: 'white'
    },
    'pink': {
        main: '#E040FB',
        light: 'rgb(230, 102, 251)',
        dark: 'rgb(156, 44, 175)',
    },
    'purple': {
        main: '#551a8b',
        light: '#bd93f9'
    },
    'grey': {
        main: '#3D4D61'
    }
};

export default colors;
